var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", { attrs: { id: "frm_pop" } }, [
    _c("div", { staticClass: "popup_wrap", staticStyle: { width: "520px" } }, [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(this.$t("msg.CSBK200_P2.004")) + " "),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "clearfix mb8" }, [
            _c("span", { staticClass: "tit_label" }, [
              _vm._v(_vm._s(this.$t("msg.CSBK200_P2.002"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchInput,
                  expression: "searchInput",
                },
              ],
              staticClass: "wid200",
              attrs: { id: "searchInput", type: "text" },
              domProps: { value: _vm.searchInput },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchTransCompany()
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.searchInput = $event.target.value
                  },
                  function ($event) {
                    return _vm.checkTransNm()
                  },
                ],
              },
            }),
            _c(
              "a",
              {
                staticClass: "button blue sh ml10",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.searchTransCompany()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CMBA100.528")))]
            ),
          ]),
          _c("p", { staticClass: "txt_desc asterisk" }, [
            _vm._v(" " + _vm._s(this.$t("msg.CSBK200_P2.001")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(" " + _vm._s(this.$t("msg.CSBK200_P2.003")) + " "),
          ]),
          _c("div", {
            staticStyle: { width: "100%", height: "200px" },
            attrs: { id: "realgrid" },
          }),
        ]),
        _vm.items.length > 0
          ? _c("div", { staticClass: "paging" }, [
              _vm.isPaging1
                ? _c(
                    "a",
                    {
                      staticClass: "btn prev",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setPrevPage()
                        },
                      },
                    },
                    [_vm._v("Prev")]
                  )
                : _vm._e(),
              _c("a", [
                _vm._v(
                  _vm._s(_vm.currentPage + 1) + "/" + _vm._s(_vm.totalPage)
                ),
              ]),
              _c(
                "a",
                {
                  staticClass: "btn next",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.setNextPage()
                    },
                  },
                },
                [_vm._v("Next ")]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }