<template>
  <form id="frm_pop">
    <div
      class="popup_wrap"
      style="width:520px;"
    >
      <button
        type="button"
        class="layer_close"
        @click="$emit('close')"
      >
        close
      </button>
      <div class="popup_cont">
        <!-- popup_cont -->
        <h1 class="page_title">
          {{ this.$t('msg.CSBK200_P2.004') }} <!-- 운송사 -->
        </h1>
        <div class="content_box">
          <!-- content_box -->
          <div class="clearfix mb8">
            <span class="tit_label">{{ this.$t('msg.CSBK200_P2.002') }}</span>
            <!-- 운송사명 -->
            <input
              v-model="searchInput"
              id="searchInput"
              class="wid200"
              type="text"
              @keyup.enter="searchTransCompany()"
              @input="checkTransNm()"
            >
            <a
              class="button blue sh ml10"
              @click.prevent="searchTransCompany()"
            >{{ $t('msg.CMBA100.528') }}</a> <!-- 검색 -->
          </div>
          <p class="txt_desc asterisk">
            {{ this.$t('msg.CSBK200_P2.001') }}
            <!-- 운송사명 찾기로 검색되지 않을 경우에는 운송사명을 직접 입력을 통해서 입력해 주세요. -->
          </p>
        </div><!-- content_box // -->
        <div class="content_box mt10">
          <!-- realgrid -->
          <h2 class="content_title">
            {{ this.$t('msg.CSBK200_P2.003') }}
            <!-- 운송사명 검색 결과 -->
          </h2>
          <div
            id="realgrid"
            style="width: 100%; height: 200px"
          />
        </div><!-- content_box // -->

        <!-- paging -->
        <div
          v-if="items.length > 0"
          class="paging"
        >
          <a
            v-if="isPaging1"
            class="btn prev"
            @click.prevent="setPrevPage()"
          >Prev</a>
          <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
          <a
            class="btn next"
            @click.prevent="setNextPage()"
          >Next
          </a>
        </div>
      </div><!-- popup_cont -->
    </div>
  </form>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import trans from '@/api/rest/trans/trans'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'code',
    dataType: 'text'
  },
  {
    fieldName: 'name',
    dataType: 'text'
  },
  {
    fieldName: 'select'
  }
]

export const columns = [
  {
    name: 'code',
    fieldName: 'code',
    type: 'text',
    width: '40',
    header: {
        text: 'No'
    },
    readOnly: true
  },
  {
    name: 'name',
    fieldName: 'name',
    type: 'text',
    width: '120',
    header: {
       text: '운송사명'
    },
    readOnly: true
  },
  {
    name: 'select',
    fieldName: 'select',
    width: '30',
    header: {
      text: ' '
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        let str = ''
        str = '<a class="button blue sm" href="#" @click="selectedRow(' + cell.index.dataRow + ')">선택</a>'
        return str
      },
      readOnly: true
    }
  }
]

export default {
  name: 'TransCompanyPop',
  data () {
    return {
      items: [],
      searchInput: '',
      isPaging1: true,
      currentPage: 0,
      totalPage: 0
    }
  },
  created () {
  },
   mounted: function () {
    const $vm = this

      //Realgrid 설정
      //RealGrid 클래스의 속성들은 클래스 생성을 위한 타입정보로서 이미 생성된 객체들이 아니기 때문에 new로 생성
      //데이터를 담는 객체
      provider = new LocalDataProvider(true)
      //데이터를 보여주기 위한 객체
      gridView = new GridView('realgrid')
      gridView.setDataSource(provider)
      //그리드 필드 생성
      provider.setFields(fields)
      //column명
      gridView.setColumns(columns)

      gridView.displayOptions.fitStyle = 'fill'
      gridView.displayOptions.rowHeight = 30
      gridView.setEditOptions({
        editable: false,
        updatable: false
      })

      //리얼그리드 팝업 height 조절
      $('#realgrid').height('250px')

      //인디케이터 없애기
      gridView.setRowIndicator({
        visible: false
      })
      //상태바 없애기
      gridView.setStateBar({
        visible: false
      })
      //체크바 없애기
      gridView.setCheckBar({
        visible: false
      })

      //Grid 메소드
      $vm.gridEvents()

      //페이징
      gridView.setPaging(true, 10)
  },
  methods: {
    selectedRow: function (index) {
      console.log(provider.getValue(index))
    },
    //getList 가져오기
    async searchTransCompany () {
      let isOk = true
      this.items = []

      if (!this.checkTransNm()) {
        isOk = false
      }

      if (isOk) {
        //api 호출
        await trans.getTransComanyList(this.searchInput, this.selectedRow).then((data) => {
          let list = data.data.transCompanyList

          console.log(list)

          if (list.length > 0) {
            provider.setRows(list)
          } else {
            //운송사 결과 없을때 현재 검색어가 나오게끔 추가
            list = [{ code: '', name: this.searchInput }]
            provider.setRows(list)
          }

          //페이징 값 셋팅
          this.currentPage = gridView.getPage() //현재 페이지 반환
          this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
        })
      }
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    checkTransNm () {
      let isOk = true
      const frm = document.querySelector('#frm_pop')
      const selector = frm.querySelector('#searchInput')

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P040.002'))
        isOk = false
      }

      return isOk
    },
    gridEvents () {
      const TH = this
      gridView.onCellClicked = function (grid, clickData) {
        const selectedData = provider.getValues(clickData.dataRow)
        const obj = {
          tfcoCstNo: selectedData[0],
          tfcoCstNm: selectedData[1]
        }
        TH.$emit('selectFunc', obj)
        TH.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
</style>
